.background {
  width: 100%;
  height: 1366px;
  top: -158px;
  left: 0px;
}

.nav .btn-link {
  color: #fff !important;
}

.sortable {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.panel-body .step {
  display: none;
}

.panel-body .step.active {
  display: block;
}

.hidden, .field-preference-id {
  display: none;
}

.panel-body .create-task {
  margin-top: 20px;
}

.panel-body .panel-title {
  font-size: 12px;
}

.step .badge {
  display: inline-block;
  margin-left: 0.5rem;
}

.panel-body .panel-heading:not(.disabled):hover {
  cursor: pointer;
}

.panel-body table th {
  width: 25%;
}

table {
  font-size: 12px;
}

table .label {
  margin-right: 1rem;
}

.milestone-title .label {
  margin-right: 0.5rem;
}

.external-cost-label {
  margin-top: 1rem;
}

.box, .box-container {
  height: auto !important;
}

$color-primary: #ff7300;
$color-secondary: #00a742;


// Login screen
body .modal-content .bg-primary{
  background: #f3f3f3 !important;
  color: #000 !important;
}

body .modal-content .bg-primary a{
  color: #000 !important;
}

body .btn-primary, .btn-success{
  background: $color-primary;
  border: 0;
  transition: all ease-in-out .25s;

  &:hover{
    background: $color-secondary;
  }
}


// Defaults
h1, h2, h3, h4, h5, h6{
  margin-top: 20px;
}

// Panels
.panel{
  border: 0;
}

.has-success .form-control{
  border-color: $color-secondary !important;
}

.form-control:focus{
  border-color: $color-primary;
}

// Panel body
.panel-body h2{
  margin-left: 11px !important;
}

#dossier-form #contact{
  background: #f3f3f3 !important;
}

#contentWrapper{
  background-color: #f3f3f3l !important;
}

.disabled{
  pointer-events: none;
  opacity: .4;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th{
  width: 0;
}

.error a{
  background: red !important;
  color: #fff !important;
}

.error-message{
  padding: 2rem 0;
  color: red;

}